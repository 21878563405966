function onFetchInit(data) {
  let form = {}
  let typeData = {}
  data.configLabels.forEach((e, i) => {
    form['name' + i] = e.name
    e.labelTypes.forEach((el, index) => {
      typeData['label' + index] = el.farmLabelDataVOS.map(el => ({
        name: el.labelName,
        value: el.value,
        select: el.status == 0 ? 1 : 0
      }))
      form['label' + index] = typeData['label' + index]
    })
  })
  return {
    form,
    typeData
  }
}

function onSubmit({ detail, form }) {
  const obj = { ...detail }
  obj['configLabels'][0]['name'] = form['name0']
  obj['configLabels'][0]['labelTypes'].forEach((e, i) => {
    e.farmLabelDataVOS.forEach((el, index) => {
      el.status = form['label' + i][index].select == 0 ? 1 : 0
    })
  })
  return obj
}

export default {
  type: 'form',
  url: '/config/farmConfigLabel/list',
  edit: '/config/farmConfigLabel/update',
  params: {
    type: '2'
  },
  onFetchInit,
  onSubmit,
  data: (netData = {}) => {
    if (!netData.configLabels) return []
    return [
      {
        name: '民宿农舍 - 标签',
        type: 'input',
        key: 'name0'
      },
      ...netData.configLabels[0].labelTypes.map((el, index) => {
        return {
          name: el.labelTypeName,
          type: 'labelGroup',
          key: 'label' + index
        }
      })
    ]
  }
}
